<template>
    <a-modal
      :visible="visible"
      :title="form.id ? '编辑' : '新增'"
      :mask-closable="false"
      :width="600"
      centered
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template #footer>
        <a-space>
          <a-button key="back" @click="handleCancel">取消</a-button>
          <a-button key="submit" type="primary" @click="handleOk">
            确认
          </a-button>
        </a-space>
      </template>
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        
        <a-form-item label="头像" v-bind="validateInfos.avatar">
          <iss-image-upload
            v-model:value="form.avatar"
            :limit-size="8"
            list-type="picture-card"
            accept=".jpe,.jpeg,.jpg,.png"
            @file="fileData"
          />
          <div class="font-color font-size">
            建议图片上传宽高比为1:1，大小不超过1M，格式为JPE、JPG、JPEG或PNG
          </div>
        </a-form-item>
        <a-form-item label="呢称" v-bind="validateInfos.userName">
          <a-input  v-model:value="form.userName" placeholder="请输入呢称" />
        </a-form-item>

        <a-form-item label="金币数量" v-bind="validateInfos.goldNum">
          <a-input-number
                v-model:value="form.goldNum"
                placeholder="请输入正整数"
                style="width: 100%"
                :min="0"
              />
        </a-form-item>
      
      </a-form>
    </a-modal>
  </template>
  <script>
  // toRaw,
  import { reactive, toRefs, watch } from 'vue';
  // import { useRoute } from 'vue-router';
  import { Form, message, Space, InputNumber, } from 'ant-design-vue';
  import IssImageUpload from '@/components/imageUpload';
  import { useStore } from 'vuex';
  import userApi from '@/api/user.js';
  
  export default {
    components: {
      AForm: Form,
      AFormItem: Form.Item,
      ASpace: Space,
      IssImageUpload,
      AInputNumber: InputNumber,


    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      initValue: {
        type: Object,
        default: () => ({}),
      },
    },
    setup(props, context) {
      // const route = useRoute();
      const store = useStore();
      const required = { required: true, message: '不能为空' };
      const whitespace = {
        whitespace: true,
        message: '不能为空',
        trigger: 'change',
      };
      const form = reactive({
        id: '',
        avatar: '',
        userName: null,
        goldNum: '',
        userId: '',

      });
      const state = reactive({
        formList: [],
        agreementList: [],
        meetingList: [],
      });
      const { validateInfos, validate, resetFields } = Form.useForm(form, {
        avatar: [required],
        goldNum: [required,],
        userName: [required, { max: 150, message: '长度不能超过50个字符' }],

    });
      watch(
        () => props.initValue,
        initValue => {

          if (initValue.userId) {
            Object.assign(form, initValue);
            form.id = initValue.userId

          } else {
            resetFields();
          }
        },
        { deep: true }
      );

      const fileData = (value) => {
        console.log('value', value)
        form.avatar = value

      };
  
      return {
        ...toRefs(state),
        form,
        store,
        validateInfos,
        fileData,
        handleCancel: () => {
          context.emit('update:visible', false);
        },
        handleOk: () => {
          validate().then(() => {
            let type = 'saveActivity';
            form.id && (type = 'updateOrderActivity');
  
            userApi[type]('', form).then(() => {
              message.success('操作成功');
              context.emit('fnOk', false);
              resetFields();
            });
          });
        },
      };
    },
  };
  </script>
  
  <style scoped lang="less">
  .font-color {
    color: #86909c;
  }
  .font-size {
    font-size: 12px;
  }
  </style>
  